@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "@fontsource/titillium-web";

/* Global styles */

.App {
  background-color: #49a078;

  color: #1f2421;
}

.app-header {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.app-header h3 {
  font-family: "Merriweather", "Times New Roman", Inter, serif;
}

.app-codeSection {
  display: flex;
}

.app-navigation {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
}

a {
  /*color: #68279B;
    color: #75040C;*/
  color: #750431;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #f50665;
}

hr {
  width: 10%;
}

.button {
  cursor: pointer;
  margin-right: 35px;
  border: 2px solid #151617;
  border-width: 3px;
  padding: 10px 15px;
  font-family: "Merriweather", "Times New Roman", Inter, serif;
}

.button:hover {
  background-color: #216869;
  color: #dce1de;
}

.background {
  background-color: red;
}

.bio-paragraph {
  width: 100%;
}

.code-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
}

.code-item img {
  width: 100%;
  height: auto;
  margin-right: 1rem;
}

.content-section {
  min-height: 60vh;
  background-color: #9cc5a1;
}

.content-header {
  padding: 40px 0 30px 0;
  font-weight: 700;
  font-size: 28px;
  font-family: Inter;
}

.content-body {
  padding: 0 12% 5%;
  font-family: Inter;
  font-size: 22px;
  text-align: left;
}

.content-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.alternate-section-background {
  background-color: #49a078;
}

.footer-container {
  text-align: center;
  background-color: #216869;
  color: #dce1de;
  padding: 18px;
}

.footer-container div {
  font-size: 14px;
  padding-bottom: 10px;
}

.footer-container a {
  display: inline-block;
}

.footer-container img {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.profile-pic {
  width: 100%;
}

/*-----Media query for desktop-----*/

@media all and (min-width: 708px) {
  .app-header {
    font-size: 26px;
  }

  .app-navigation {
    font-size: 20px;
  }

  .background {
    background-color: blue;
  }

  .bio-paragraph {
    width: 60%;
  }

  .button {
    border-width: 4px;
  }

  .code-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: none;
  }

  .code-item img {
    width: 30%;
    height: auto;
    margin-right: 1rem;
  }

  .profile-pic {
    width: 38%;
    height: 38%;
  }
}
